import heroimagelow from "./assets/heroimage.png";

export const faqs = [
  {
    question:
      "Is there a registration fee to participate in the Edge AI Innovation Challenge 2024?",
    answer: "No, registration for the challenge is free of charge.",
  },
  {
    question:
      "Can teams submit proposals and prototypes developed in previous competitions or projects?",
    answer:
      "No, submissions must be original and developed specifically for the Edge AI Innovation Challenge 2024.",
  },
  {
    question:
      "Are there restrictions on the size or complexity of prototypes developed in the challenge?",
    answer:
      "No specific restrictions are imposed, but prototypes should demonstrate practical applications and feasibility within industrial contexts.",
  },
  {
    question:
      "What recognition do participants receive at each stage of the challenge?",
    answer:
      "Certificates will be issued at each stage to acknowledge participants contributions and achievements in advancing Edge AI technology.",
  },
  {
    question: "How are winners determined in the challenge?",
    answer:
      "Winners are determined based on the evaluation of final prototypes, focusing on performance, innovation, and market potential.",
  },
  {
    question: "What should teams do if they do not have development boards?",
    answer:
      "Shortlisted teams can utilize the STM32 development kits available with the department labs",
  },
];

export const images = [heroimagelow];

export const criteria = [
  {
    title: "Team Composition:",
    description:
      "Each team must consist of a minimum of 2 members and a maximum of 4 members.",
  },
  {
    title: "Passion for Innovation:",
    description:
      "We encourage teams to demonstrate a passion for innovation in their proposed solutions and problem-solving approaches.",
  },
  {
    title: "All Years of Engineering Students across India(UG/PG):",
    description:
      "The challenge is open to students from all years of engineering programs, fostering interdisciplinary collaboration and creativity.",
  },
  {
    title: "Innovation Encouraged:",
    description:
      "Teams are encouraged to propose innovative and real-time problem-solving ideas that demonstrate the practical application of AI in industrial contexts.",
  },
];
